import React from "react";

const Session = () => {
  return (
    <>
      <div className="title">
        <h1>Session Playing</h1>
      </div>
      <div className="teaching-div">
        <p>
          Session recording services available at my own private studio, or a
          place of your choice. Contact me for details.
        </p>
        <p>More information coming soon.</p>
      </div>
    </>
  );
};

export default Session;
