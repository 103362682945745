import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Video = () => {
  const videos = [
    {
      id: "1",
      name: "Side by side",
      composer: "Michio Kitazume",
      videoURL: "https://youtu.be/N9yzBNMhEsc",
      src: "imgs/lucia-side.png",
    },
    {
      id: "2",
      name: "Etude n.2",
      composer: "Vic Firth",
      videoURL: "https://www.youtube.com/watch?v=71LYLOKm51k&feature=emb_title",
      src: "imgs/lucia-etude2.png",
    },
    {
      id: "3",
      name: "Etude n.1",
      composer: "Jacques Delécluse",
      videoURL: "https://www.youtube.com/watch?v=ONZPyuRhIEM",
      src: "imgs/lucia-etude1.png",
    },
    {
      id: "3",
      name: "Improvisation",
      composer: "Elliott Carter",
      videoURL: "https://www.youtube.com/watch?v=rrYcPcA68Ic",
      src: "imgs/lucia-carter.png",
    },
  ];
  return (
    <Container>
      <div className="title">
        <h1>Video</h1>
      </div>
      <Row style={{ textAlign: "center", margin: "auto" }}>
        {videos.map((item) => (
          <Col lg="6" key={item.id}>
            <div className="video-image">
              <a href={item.videoURL} target="_blank">
                <img
                  src={item.src}
                  alt={item.name}
                  className="video-thumbnail"
                />
                <p className="video-click">
                  Click to open the video in a new tab.
                </p>
              </a>
              <p className="video-description">
                <span id="video-name">{item.name}</span> by {item.composer}
              </p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Video;
