import React from "react";

const Teaching = () => {
  return (
    <>
      <div className="title">
        <h1>Teaching</h1>
      </div>
      <div className="teaching-div">
        <p>
          Private tutoring in Music and Percussion for all ages and levels is
          offered in English, Swedish and Portuguese.
        </p>
        <p>The lessons take place in a private studio in Ringön, Gothenburg.</p>
        <p>
          Please contact me for further information on fees and availability.
        </p>
      </div>
    </>
  );
};

export default Teaching;
