import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import TeachingCV from "../imgs/teachingCV.pdf";
import OrchestraCV from "../imgs/orchestraCV.pdf";

const About = () => {
  return (
    <Container className="about-container">
      <div className="title">
        <h1>About</h1>
      </div>
      <Row>
        <Col lg="6" style={{ paddingTop: "2em" }}>
          <img src="imgs/lucia-about.jpg" id="about-image" />
        </Col>
        <Col lg="6" style={{ paddingTop: "2em" }}>
          <p>
            Lúcia Viana da Silva is a percussionist educated in classical
            percussion who mostly dedicates to symphonic music but has a broad
            set of expertise and experience, from orchestral percussion to
            contemporary music, chamber and solo repertoire.
          </p>
          <p>
            <span className="paragraph-inset" />
            She has an active freelancing career, regularly playing in the
            percussion section of the{" "}
            <a
              className="about-links"
              href="https://www.gso.se/"
              target="_blank"
            >
              Gothenburg Symphony Orchestra
            </a>{" "}
            and{" "}
            <a
              className="about-links"
              href="https://en.opera.se/"
              target="_blank"
            >
              Gothenburg’s Opera
            </a>
            , besides sporadic collaborations with several ensembles, while also
            keeping an active role in pedagogy, having worked as a percussion
            teacher in several schools in Portugal and Sweden, and is currently
            the percussion tutor for the{" "}
            <a
              className="about-links"
              href="http://www.elsistema.se/om-el-sistema/in-english/dream-orchestra/"
              target="_blank"
            >
              Dream Orchestra
            </a>
            , an exciting and innovative project that is part of{" "}
            <a
              className="about-links"
              href="http://www.elsistema.se/om-el-sistema/in-english/"
              target="_blank"
            >
              El Sistema Sweden
            </a>
            .
          </p>
          <p>
            <span className="paragraph-inset" />
            As an orchestral percussionist, Lúcia has the opportunity to work
            with several professional orchestras such as Gothenburg Symphony
            Orchestra (SE), Gothenburg Opera (SE), Gulbenkian Foundation
            Orchestra (PT) and Symphonic Orchestra of Oporto Casa da Música
            (PT), as well as youth orchestras such as the University of
            Gothenburg Symphony Orchestra (SE), Baltic Youth Academy (EE),
            Galiza Youth Orchestra (ES) and Portuguese Youth Orchestra (PT). It
            is worth mentioning the work with some relevant international
            conductors such as Kent Nagano, Santu-Mattias Rouvalli, Jukka-Pekka
            Saraste, Neeme Järvi, Lionel Bringuier, Petri Sakari, Joana Carneiro
            and Henrik Schafer.
          </p>
          <p>
            <span className="paragraph-inset" />
            After finishing her Master’s Degree in 2017 in Symphonic Orchestra
            Performance at the Academy of Music and Drama, University of
            Gothenburg, Sweden, Lúcia was awarded the{" "}
            <span style={{ fontWeight: "bold" }}>
              National Scholarship of the Swedish Royal Music Academy
            </span>{" "}
            three years in a row.
          </p>
          <p>
            <span className="paragraph-inset" />
            She is currently mentored by the percussionist Roger Carlsson.
            During the past few years, she has studied with Hans Hernqvist,
            Miquel Bernat, Nuno Aroso, Daniel Berg, Helena Pereira and has been
            to masterclasses with Anders Astrand, Pedro Carneiro, Nancy
            Zeltsman, Jean Geoffrey, among others.
          </p>
        </Col>
      </Row>
      <div style={{ textAlign: "center" }}>
        <a href={TeachingCV} target="_blank" id="about-page-link">
          <button className="cv-btn">TEACHING CV</button>
        </a>
        <a href={OrchestraCV} target="_blank" id="about-page-link">
          <button className="cv-btn">ORCHESTRAL CV</button>
        </a>
      </div>
    </Container>
  );
};

export default About;
