import React from "react";
import { Navbar, Nav } from "react-bootstrap";

const NavBar = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      className="navbar"
    >
      <Navbar.Brand
        href="/home"
        style={{ paddingRight: "10px" }}
        className="navbar-brand"
      >
        Lúcia Viana da Silva
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/video">Video</Nav.Link>
          <Nav.Link href="/teaching">Teaching</Nav.Link>
          <Nav.Link href="/session-playing">Session Playing</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
        <Nav>
          <span>
            <a
              href="https://www.facebook.com/lucia.vianadasilva"
              target="_blank"
              className="fab fa-facebook-f nostyle navbar-custom-links"
            />
            <a
              style={{ marginLeft: "1em" }}
              href="https://m.me/lucia.vianadasilva"
              target="_blank"
              className="fab fa-facebook-messenger nostyle navbar-custom-links"
            />
            <a
              style={{ marginLeft: "1em" }}
              href="https://www.instagram.com/luciavianadasilva/"
              target="_blank"
              className="fab fa-instagram nostyle navbar-custom-links"
            />
          </span>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
