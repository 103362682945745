import React from "react";

const Contact = () => {
  return (
    <>
      <div className="title">
        <h1>Contact Me</h1>
      </div>
      <div className="contact-div">
        <p>
          <span className="contact-bold">Email</span>{" "}
          Lucia.VianadaSilva@gmail.com
        </p>
        <p>
          <span className="contact-bold">Phone number</span> +46 (0)70 246 95 52
        </p>
        <p>
          <a
            href="https://www.facebook.com/lucia.vianadasilva"
            target="_blank"
            className="fab fa-facebook-f nostyle contact-links"
          />
          <a
            style={{ marginLeft: "1em" }}
            href="https://m.me/lucia.vianadasilva"
            target="_blank"
            className="fab fa-facebook-messenger nostyle contact-links"
          />
          <a
            style={{ marginLeft: "1em" }}
            href="https://www.instagram.com/luciavianadasilva/"
            target="_blank"
            className="fab fa-instagram nostyle contact-links"
          />
        </p>
      </div>
    </>
  );
};

export default Contact;
