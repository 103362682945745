import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import About from "./pages/About";
import Contact from "./pages/Contact";
import Landing from "./pages/Landing";
import NavBar from "./components/NavBar";
import NotFound from "./pages/NotFound";
import Session from "./pages/Session";
import Teaching from "./pages/Teaching";
import Video from "./pages/Video";
import "./App.scss";

function App() {
  return (
    <>
      <NavBar />
      <div className="App">
        <Switch>
          <Route path="/home" component={Landing} />
          <Route path="/about" component={About} />
          <Route path="/video" component={Video} />
          <Route path="/session-playing" component={Session} />
          <Route path="/teaching" component={Teaching} />
          <Route path="/contact" component={Contact} />

          <Route path="/not-found" component={NotFound} />
          <Redirect from="/" exact to="/home" />
          <Redirect to="/not-found" />
        </Switch>
      </div>
    </>
  );
}

export default App;
