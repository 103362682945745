import React from "react";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <>
      <div className="background background-image" />
      <div className="landing-page-cta">
        <Link to="/about" id="landing-page-link">
          Click here to enter the website
        </Link>
      </div>
    </>
  );
};

export default Landing;
